import React from 'react';
import {Helmet} from 'react-helmet';
import {graphql, useStaticQuery} from 'gatsby';

import autosaveIcon from '../assets/autosave-icon.png';

const SiteMetadata = ({pathname}) => {
	const {
		site: {
			siteMetadata: {siteUrl, title, twitter, description},
		},
	} = useStaticQuery(graphql`
		query SiteMetadata {
			site {
				siteMetadata {
					siteUrl
					title
					twitter
					description
				}
			}
		}
	`);

	return (
		<Helmet defer={false} defaultTitle={title} titleTemplate={`${title}`}>
			<html lang="en" />
			<link rel="canonical" href={`${siteUrl}${pathname}`} />
			<meta name="docsearch:version" content="2.0" />
			<meta
				name="viewport"
				content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
			/>
			<meta name="author" content="AutoSave" />
			<meta name="copyright" content="Autosave Financial Services Pvt. Ltd." />
			<meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
			<meta property="og:url" content={siteUrl} />
			<meta property="og:type" content="website" />
			<meta property="og:locale" content="en" />
			<meta property="og:site_name" content={title} />
			<meta name="og:description" content={description} />
			<meta name="description" content={description} />
			<meta property="og:image" content={`${siteUrl}${autosaveIcon}`} />
			<meta property="og:image:secure_url" content={`${siteUrl}${autosaveIcon}`} />
			<meta property="og:image:width" content="512" />
			<meta property="og:image:height" content="512" />
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:domain" content={siteUrl} />
			<meta name="twitter:site" content={twitter} />
			<meta name="twitter:image:src" content={`${siteUrl}${autosaveIcon}`} />
			<meta
				name="keywords"
				content="pension india, nps india, save tax, 80ccd(2), 80ccd(1b), employee benefit"
			/>
		</Helmet>
	);
};

export default SiteMetadata;
