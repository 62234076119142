import React from 'react';
import getFirebase, {FirebaseContext} from '../components/Firebase';
import withAuthentication from '../components/Session/withAuthentication';
import SiteMetadata from '../components/site-metadata';

import '../styles/reset.css';

class Layout extends React.Component {
	constructor() {
		super();
		this.state = {
			firebase: null,
		};
	}

	componentDidMount() {
		const app = import('firebase/app');
		const auth = import('firebase/auth');
		const database = import('firebase/database');
		const amplitude = require('amplitude-js');
		amplitude.getInstance().init('6815b8bb4996cf1bc27e9acccd72a712');
		Promise.all([app, auth, database]).then((values) => {
			const firebase = getFirebase(values[0]);

			this.setState({firebase});
		});
	}

	render() {
		return (
			<FirebaseContext.Provider value={this.state.firebase}>
				<SiteMetadata pathname={this.props.location.pathname} />
				<AppWithAuthentication {...this.props} />
			</FirebaseContext.Provider>
		);
	}
}

const AppWithAuthentication = withAuthentication(({children}) => (
	<React.Fragment>{children}</React.Fragment>
));

export default Layout;
