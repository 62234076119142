// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-template-knowledge-base-markdown-js": () => import("../src/templates/template-knowledgeBase-markdown.js" /* webpackChunkName: "component---src-templates-template-knowledge-base-markdown-js" */),
  "component---src-templates-template-product-details-firestore-js": () => import("../src/templates/template-productDetails-firestore.js" /* webpackChunkName: "component---src-templates-template-product-details-firestore-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-js": () => import("../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-admin-js": () => import("../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-auth-intro-js": () => import("../src/pages/auth-intro.js" /* webpackChunkName: "component---src-pages-auth-intro-js" */),
  "component---src-pages-deposits-js": () => import("../src/pages/deposits.js" /* webpackChunkName: "component---src-pages-deposits-js" */),
  "component---src-pages-faq-js": () => import("../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-forgot-password-old-js": () => import("../src/pages/forgot-password-old.js" /* webpackChunkName: "component---src-pages-forgot-password-old-js" */),
  "component---src-pages-forgot-password-email-js": () => import("../src/pages/forgotPasswordEmail.js" /* webpackChunkName: "component---src-pages-forgot-password-email-js" */),
  "component---src-pages-home-js": () => import("../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-knowledge-base-index-js": () => import("../src/pages/knowledge-base/index.js" /* webpackChunkName: "component---src-pages-knowledge-base-index-js" */),
  "component---src-pages-merchant-js": () => import("../src/pages/merchant.js" /* webpackChunkName: "component---src-pages-merchant-js" */),
  "component---src-pages-mobile-verification-js": () => import("../src/pages/mobileVerification.js" /* webpackChunkName: "component---src-pages-mobile-verification-js" */),
  "component---src-pages-qr-reader-js": () => import("../src/pages/qr-reader.js" /* webpackChunkName: "component---src-pages-qr-reader-js" */),
  "component---src-pages-signin-js": () => import("../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-sign-in-with-mobile-js": () => import("../src/pages/signInWithMobile.js" /* webpackChunkName: "component---src-pages-sign-in-with-mobile-js" */),
  "component---src-pages-signup-js": () => import("../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-survey-js": () => import("../src/pages/survey.js" /* webpackChunkName: "component---src-pages-survey-js" */),
  "component---src-pages-wallet-js": () => import("../src/pages/wallet.js" /* webpackChunkName: "component---src-pages-wallet-js" */)
}

