import _ from 'lodash';
import 'firebase/firestore';

const config = {
	apiKey: process.env.GATSBY_API_KEY,
	authDomain: process.env.GATSBY_AUTH_DOMAIN,
	databaseURL: process.env.GATSBY_DATABASE_URL,
	projectId: process.env.GATSBY_PROJECT_ID,
	storageBucket: process.env.GATSBY_STORAGE_BUCKET,
	messagingSenderId: process.env.GATSBY_MESSAGING_SENDER_ID,
};

class Firebase {
	constructor(app) {
		app.initializeApp(config);

		/* Helper */

		this.serverValue = app.database.ServerValue;
		this.emailAuthProvider = app.auth.EmailAuthProvider;
		this.phoneAuthProvider = app.auth.PhoneAuthProvider;

		/* Firebase APIs */
		this.app = app;
		this.store = app.firestore();
		this.auth = app.auth();
		this.db = app.database();

		/* Social Sign In Method Provider */
		this.googleProvider = new app.auth.GoogleAuthProvider();

		if(document.getElementById('sign-in-button')) {
			this.createRecaptcha();
		}
	}

	createRecaptcha = () => {
		this.recaptchaVerifier = new this.app.auth.RecaptchaVerifier('sign-in-button', {
			size: 'invisible',
			callback: function(response) {
				// reCAPTCHA solved, allow signInWithPhoneNumber.
				onSignInSubmit();
			},
		});
		return this.recaptchaVerifier;
	}

	clearRecaptcha = () => this.recaptchaVerifier.clear();

	// *** Auth API ***

	doCreateUserWithEmailAndPassword = (email, password) =>
		this.auth.createUserWithEmailAndPassword(email, password);

	doSignInWithEmailAndPassword = (email, password) =>
		this.auth.signInWithEmailAndPassword(email, password);

	doLinkCredToExistingAccount = (credential) => 
		this.auth.currentUser.linkWithCredential(credential);

	doLinkWithPhoneNumber = (phoneNumber) => 
		this.auth.currentUser.linkWithPhoneNumber(phoneNumber, this.recaptchaVerifier);
	
	doGetCurrentUser = () => this.auth.currentUser;

	doGetPhoneCredentials = (verificationId, codeInput) => 
		this.phoneAuthProvider.credential(verificationId, codeInput);

	doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

	doSignInWithPhone = (phoneNumber) =>
		this.auth.signInWithPhoneNumber(phoneNumber, this.recaptchaVerifier);

	doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

	doSignOut = () => this.auth.signOut();

	doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

	doSendEmailVerification = () =>
		this.auth.currentUser.sendEmailVerification({
			url: process.env.GATSBY_CONFIRMATION_EMAIL_REDIRECT,
		});

	doPasswordUpdate = (password) => this.auth.currentUser.updatePassword(password);

	// *** Merge Auth and DB User API *** //

	onAuthUserListener = (next, fallback) => 
		this.auth.onAuthStateChanged((authUser) => {
			if (authUser) {
				this.user(authUser.uid)
					.once('value')
					.then((snapshot) => {
						const dbUser = snapshot.val();

						// default empty roles
						if (dbUser != null && !dbUser.roles) {
							dbUser.roles = {};
						}

						// merge auth and db user
						authUser = {
							uid: authUser.uid,
							email: authUser.email,
							emailVerified: authUser.emailVerified,
							providerData: authUser.providerData,
							name: authUser.displayName,
							...dbUser,
						};

						next(authUser);
					});
			} else {
				fallback();
			}
		});

	// *** User API ***

	user = (uid) => this.db.ref(`users/${uid}`);

	users = () => this.db.ref('users');

	// *** Message API ***

	message = (uid) => this.db.ref(`messages/${uid}`);

	messages = () => this.db.ref('messages');
}

let firebase;

function getFirebase(app, auth, database) {
	if (_.isEmpty(firebase)) {
		try {
			firebase = new Firebase(app, auth, database);
		} catch (err) {
			// we skip the "already exists" message which is
			// not an actual error when we're hot-reloading
			// @TODO: Hide this is in production
			if (err.code === 'app/duplicate-app') {
				console.error(
					`Firebase initialization error.
					In our case it happens when we hot-reload,
					NOT because we're initializing twice, don't get tensed.
					${err.stack}`,
				);
			}
		}
	}

	return firebase;
}

export default getFirebase;
