import hex2rgba from 'hex2rgba';

// TODO all of _this_
import {
	// borders as b,
	breakpoints as bp,
	colors as c,
	fonts as f,
	fontSizes as fs,
	fontWeights as fw,
	letterSpacings as ls,
	lineHeights as lh,
	mediaQueries as mq,
	radii as r,
	shadows as sh,
	sizes as s,
	space as sp,
	transition as t,
	zIndices as z,
} from 'gatsby-design-tokens';

let breakpointsTokens = [];
for (let b in bp) {
	breakpointsTokens.push(bp[b]);
}

// remove the first breakpoint, `xxs: 0`
// this made sense for styled-system and using an object
// to define breakpoints, but not here
breakpointsTokens.splice(0, 1);

let fontsTokens = {};
for (let fontFamily in f) {
	fontsTokens[fontFamily] = f[fontFamily].join(`, `);
}
// https://theme-ui.com/theme-spec#typography
// TODO adjust keys in gatsby-design-tokens
fontsTokens.body = fontsTokens.system;
fontsTokens.heading = fontsTokens.header;

const fontSizesTokens = fs.map((token) => `${token / 16}rem`);
const spaceTokens = sp.map((token) => `${token / 16}rem`);

const lineHeightsTokens = {
	...lh,
	body: lh.default,
	heading: lh.dense,
};

const darkBackground = `#fff`; // meh
const darkBorder = c.grey[90];
// const darkBackground = c.teal[90]
// const darkBorder = c.teal[80]
const shadowDarkBase = `19,18,23`;
const shadowDarkFlares = `0,0,0`;

const fweights = {
	body: fw[0],
	bold: fw[1],
	medium: `600`,
	heading: fw[1],
	headingPrimary: fw[2],
};

const col = {
	...c,
	// https://theme-ui.com/theme-spec#color
	// Theme-UI required keys
	//
	// Body foreground color
	// overwrite what's currently in `colors` from `gatsby-design-tokens`
	// also see `heading` key below
	text: c.grey[80], // c.text.primary
	// Body background color
	background: c.white,
	// Primary brand color for links, buttons, etc.
	primary: `#80E8FE`,
	// c.teal[20],
	// A secondary brand color for alternative styling
	secondary: c.teal[40],
	// A contrast color for emphasizing UI
	accent: c.orange[60],
	// A faint color for backgrounds, borders, and accents that do not require high contrast with the background color
	muted: c.grey[5],
	// end Theme-UI required keys
	// gatsby-design-tokens has the following in colors.text,
	// which conflicts with theme-ui's default color `text`
	// making text.header and text.secondary available as
	// `heading` and `textMuted` resolves that
	heading: c.text.header, // text.header
	textMuted: c.text.secondary, // text.secondary
	// moved `text.placeholder` to `input.placeholder`
	// ref. e.g. https://github.com/system-ui/theme-ui/blob/702c43e804046a94389e7a12a8bba4c4f436b14e/packages/presets/src/tailwind.js#L6
	// transparent: `transparent`,
	// expand `gatsby-design-tokens` defaults
	ui: {
		background: c.grey[5],
		hover: c.teal[5],
		border: c.grey[20],
	},
	link: {
		color: c.teal[50],
		border: c.teal[30],
		hoverBorder: c.teal[50],
		hoverColor: c.teal[60],
	},
	icon: {
		dark: c.teal[60],
		neutral: c.grey[50],
		neutralLight: c.grey[30],
		background: c.white,
		accent: c.yellow[60],
		light: c.teal[10],
		lightActive: c.teal[20],
	},
	input: {
		background: c.white,
		backgroundFocus: c.white,
		border: c.grey[30],
		focusBorder: c.white,
		focusBoxShadow: c.teal[60],
		icon: c.grey[50],
		iconFocus: c.grey[60],
		placeholder: c.text.placeholder,
	},

	// new tokens
	card: {
		background: c.white,
		color: c.grey[50],
		header: c.black,
		starterLabelBackground: c.teal[5],
		starterLabelText: c.teal[70],
		pluginLabelBackground: c.orange[5],
		pluginLabelText: c.orange[90],
	},
	modal: {
		background: c.white,
		overlayBackground: hex2rgba(c.white, 0.95),
	},
	navigation: {
		background: hex2rgba(c.white, 0.985),
		linkDefault: c.grey[70],
		linkActive: c.teal[50],
		linkHover: c.teal[30],
		socialLink: c.grey[40],
	},
	search: {
		suggestionHighlightBackground: c.lavender,
		suggestionHighlightColor: c.teal[30],
	},
	sidebar: {
		itemHoverBackground: hex2rgba(c.teal[20], 0.275),
		itemBackgroundActive: `transparent`,
		itemBorderColor: `transparent`, // `rgba(0,0,0,0.05)`,
		activeSectionBackground: hex2rgba(c.teal[20], 0.15),
		itemBorderActive: c.teal[10],
	},
	themedInput: {
		background: c.grey[10],
		backgroundFocus: c.white,
		focusBorder: c.teal[60],
		focusBoxShadow: c.teal[30],
		icon: c.grey[50],
		iconFocus: c.grey[60],
		placeholder: c.grey[60],
	},
	widget: {
		background: c.white,
		color: c.text.primary,
	},
	button: {
		primaryBg: c.teal[60],
		primaryText: c.white,
		primaryBorder: c.teal[60],
		secondaryBg: `transparent`,
		secondaryText: c.teal[50],
		secondaryBorder: c.teal[40],
	},
	modes: {
		dark: {
			background: darkBackground,
			text: c.grey[30],
			heading: c.whiteFade[90],
			textMuted: c.grey[40],
			muted: c.grey[90],
			icon: {
				dark: c.teal[50],
				neutral: c.grey[70],
				neutralLight: c.grey[90],
				background: c.darkBorder,
				accent: c.yellow[50],
				light: c.grey[90],
				lightActive: c.teal[90],
			},
			modal: {
				background: darkBackground,
				overlayBackground: hex2rgba(darkBackground),
			},
			link: {
				border: c.teal[90],
				color: c.teal[30],
				hoverBorder: c.teal[70],
				hoverColor: c.teal[30],
			},
			navigation: {
				background: hex2rgba(darkBackground),
				linkActive: c.teal[40],
				linkDefault: c.whiteFade[60],
				linkHover: c.white,
				socialLink: c.grey[60],
			},
			themedInput: {
				background: darkBorder,
				backgroundFocus: `black`,
				focusBorder: c.teal[60],
				focusBoxShadow: c.teal[60],
				icon: c.grey[50],
				iconFocus: c.teal[50],
				placeholder: c.whiteFade[50],
			},
			// TODO figure out how to make shadows themeable
			shadows: {
				dialog: `0px 4px 16px rgba(${shadowDarkBase}, 0.08), 0px 8px 24px rgba(${shadowDarkFlares}, 0.16)`,
				floating: `0px 2px 4px rgba(${shadowDarkBase}, 0.08), 0px 4px 8px rgba(${shadowDarkFlares}, 0.16)`,
				overlay: `0px 4px 8px rgba(${shadowDarkBase}, 0.08), 0px 8px 16px rgba(${shadowDarkFlares}, 0.16)`,
				raised: `0px 1px 2px rgba(${shadowDarkBase}, 0.08), 0px 2px 4px rgba(${shadowDarkFlares}, 0.08)`,
			},
			sidebar: {
				itemBackgroundActive: `transparent`,
				activeSectionBackground: hex2rgba(c.teal[90], 0.2),
				itemBorderActive: c.teal[80],
				itemBorderColor: `transparent`,
				itemHoverBackground: hex2rgba(c.teal[90], 0.2),
			},
			ui: {
				background: darkBackground,
				hover: c.teal[90],
				border: darkBorder,
			},
			widget: {
				background: darkBackground,
				border: darkBorder,
				color: c.white,
			},
			button: {
				secondaryBg: `transparent`,
				secondaryText: c.teal[40],
				secondaryBorder: c.teal[40],
			},
		},
	},
};

const si = {
	...s,
	// TODO remove `sizes` from `gatsby-design-tokens`
	// until we eventually have well-defined components,
	// it doesn't make sense to store these tokens in
	// the package
	headerHeight: `4rem`,
	logo: spaceTokens[6],
	pluginsSidebarWidthDefault: `21rem`,
	pluginsSidebarWidthLarge: `24rem`,
	showcaseSidebarMaxWidth: `15rem`,
	sidebarItemMinHeight: spaceTokens[8],
	mainContentWidth: {
		default: `54rem`,
		withSidebar: `42rem`,
	},
	sidebarWidth: {
		default: `16.5rem`,
		large: `18rem`,
		mobile: `320px`,
	},
	tocWidth: `18rem`,
};

// export const borders = b
export const breakpoints = breakpointsTokens;
export const colors = col;
export const fonts = fontsTokens;
export const fontSizes = fontSizesTokens;
export const fontWeights = fweights;
export const letterSpacings = ls;
export const lineHeights = lineHeightsTokens;
export const mediaQueries = mq;
export const radii = r;
export const shadows = sh;
export const sizes = si;
export const space = spaceTokens;
export const transition = t;
export const zIndices = z;

const config = {
	// this enables the color modes feature
	// and is used as the name for the top-level colors object
	// `prefers-color-scheme: dark` media query
	useColorSchemeMediaQuery: true,
	// borders: borders,
	breakpoints: breakpointsTokens,
	colors: col,
	fonts: fontsTokens,
	fontSizes: fontSizesTokens,
	fontWeights: fweights,
	letterSpacings: ls,
	lineHeights: lineHeightsTokens,
	mediaQueries: mq,
	radii: r,
	shadows: sh,
	sizes: si,
	space: spaceTokens,
	transition: t,
	zIndices: z,
	buttons: {
		large: {
			fontSize: 4,
			px: 4,
			height: `52px`,
		},
		small: {
			fontSize: 2,
			py: 2,
			px: 3,
		},
	},
	links: {
		muted: {
			fontSize: 1,
			lineHeight: `solid`,
			py: 3,
			'&&': {
				border: 0,
				color: `textMuted`,
				display: `flex`,
				fontWeight: `body`,
			},
			'&&:hover': {
				color: `link.hoverColor`,
			},
		},
	},
};

export default config;
